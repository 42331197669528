import React, { useEffect } from "react";
import { useMap } from "@vis.gl/react-google-maps";

const MapController = ({ currentLocation }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !currentLocation) return;

    console.log("Centrando el mapa en:", currentLocation);

    // Cambia el centro del mapa dinámicamente
    map.panTo(currentLocation);
    map.setZoom(14); // Ajusta el nivel de zoom si es necesario
  }, [map, currentLocation]);

  return null; // No renderiza nada, solo controla el mapa
};

export default MapController;
