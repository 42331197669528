import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./contexts/userContext";
import LandingPage from "./components/landingPage"; // Importa el componente LandingPage
import AppPage from "./components/appPage"; // Importa el componente AppPage (contiene tu actual App.js)

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <UserProvider>
      <Router>
        <Routes>
          {/* Ruta principal para la Landing Page */}
          <Route path="/" element={<LandingPage />} />
          {/* Ruta para la aplicación */}
          <Route path="/app" element={<AppPage />} />
        </Routes>
      </Router>
    </UserProvider>
  </React.StrictMode>
);

// Para medir rendimiento
reportWebVitals();
