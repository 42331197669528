import React, { useState, useContext } from "react";
import { UserContext } from ".././contexts/userContext.js";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import { getDatabase, ref as databaseRef, set } from "firebase/database";
import { v4 as uuidv4 } from "uuid"; // para generar un ID único
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  Map,
  APIProvider,
  Marker,
  useGoogleMap,
} from "@vis.gl/react-google-maps";

// Custom Styled Components
const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "#C7C7C7",
    },
    borderRadius: 5,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
    borderRadius: 5,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306",
  },
  "& .Mui-focused .MuiInputBase-input": {
    backgroundColor: "#C7C7C7",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306", // This ensures the orange border on focus
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#EC5306", // Orange color for the floating label
  },
});

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fill: "gray",
    fontSize: 28,
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    fill: "#EC5306",
  },
  "&.Mui-checked": {
    color: "#EC5306",
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "#C7C7C7",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306",
  },
  "& .Mui-focused .MuiSelect-select": {
    backgroundColor: "#EC5306",
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.Mui-focused": {
    color: "#EC5306",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#EC5306",
  "&:hover": {
    backgroundColor: "#EC5306",
  },
  borderRadius: 10,
  height: 50,
  marginTop: 60,
  marginBottom: 150,
  width: "100%",
}));

export default function CargarCancha() {
  const [formData, setFormData] = useState({
    nombre: "",
    cantAros: 0,
    urls: "",
    comentarios: "",
    google_photos_url: "",
    plaza: false,
    plazaPolideportivo: false,
    polideportivo: false,
    abiertaSiempre: false,
    aptaLluvia: false,
    canchaCompleta: false,
    tieneRed: false,
  });
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [error, setError] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const { currentUser } = useContext(UserContext);

  const [neighborhood, setNeighborhood] = useState(""); // Para almacenar el barrio

  const handleMapClick = (event) => {
    const lat = event.detail.latLng.lat;
    const lng = event.detail.latLng.lng;
    setSelectedLocation({ lat, lng });

    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          // Buscar el barrio en los resultados
          const addressComponents = results[0].address_components;
          const neighborhoodComponent = addressComponents.find(
            (component) =>
              component.types.includes("sublocality") ||
              component.types.includes("neighborhood")
          );
          const neighborhoodName = neighborhoodComponent
            ? neighborhoodComponent.long_name
            : "";
          setNeighborhood(neighborhoodName);
        } else {
          console.log("No se encontraron resultados");
        }
      } else {
        console.error("Error en Geocoder: " + status);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
      //Falta pasar bien plaza/poli
    });
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleSubmit = async () => {
    // Validación de campos requeridos
    if (!formData.nombre || !formData.cantAros) {
      setError("Por favor, complete todos los campos requeridos.");
      return;
    }
    if (!selectedLocation) {
      setError(
        "Por favor, seleccione en el mapa, la ubicacion de la cancha a cargar."
      );
      return;
    }

    // Inicializar Firebase
    const db = getDatabase();
    const storage = getStorage();
    const locationId = uuidv4(); // Generar ID único para la locación

    if (currentUser) {
      try {
        // Subir imagenes a Firebase Storage
        const imageUrls = {};
        if (selectedFiles && selectedFiles.length > 0) {
          for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const storagePath = `locations/${locationId}/${uuidv4()}_${
              file.name
            }`;
            const fileRef = storageRef(storage, storagePath);

            // Sube la imagen al Storage
            await uploadBytes(fileRef, file);

            // Obtén la URL de descarga de la imagen subida
            const downloadURL = await getDownloadURL(fileRef);
            imageUrls[i] = downloadURL; // Almacena la URL en el formato {0: "url", 1: "url"}
          }
        }

        const locationUpload = {
          ...formData,
          images: imageUrls || {},
          location: selectedLocation,
          cargadaPor: currentUser.email,
          barrio: neighborhood,
        };

        //console.log("locationUpload: " + JSON.stringify(locationUpload));
        const locationRef = databaseRef(db, `locations/${locationId}`);
        await set(locationRef, locationUpload);
        // Subir datos a Firebase Realtime Database
        //await set(ref(db, `locations/${locationId}`), locationUpload);

        // Mostrar mensaje de éxito (esto es solo un ejemplo, podrías implementar una modal)
        alert("Locación agregada exitosamente");
      } catch (error) {
        console.error("Error al agregar locación:", error);
        alert(
          "Ocurrió un error al intentar agregar la locación. Por favor, intente nuevamente."
        );
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <div
        style={{
          width: "96%",
          marginLeft: "2%",
        }}
      >
        <p style={{ color: "#D9D8D8", fontWeight: "600", marginTop: 40 }}>
          SELECCIONA LA CANCHA DONDE QUERES JUGAR:{" "}
        </p>
        <div
          style={{
            width: "100%",
            height: "35vh",
            overflow: "hidden",
            borderRadius: 20,
          }}
        >
          <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
            <Map
              defaultZoom={13}
              defaultCenter={{ lat: -34.61315, lng: -58.37723 }}
              mapId={"8a45de67aeece53b"}
              mapContainerStyle={{ height: "100vh", width: "100%" }}
              onClick={handleMapClick}
            >
              {selectedLocation && <Marker position={selectedLocation} />}
            </Map>
          </APIProvider>
        </div>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          textAlign: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "85%",
          }}
        >
          <CustomTextField
            label="Nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
          <CustomTextField
            label="Cantidad de Aros"
            name="cantAros"
            value={formData.cantAros}
            onChange={handleChange}
            type="number"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*", // Esto permite solo números en dispositivos móviles
            }}
            required
          />
          <CustomTextField
            label="Url instagram comunidad"
            name="urls"
            value={formData.urls}
            onChange={handleChange}
          />
          <CustomTextField
            label="Google Fotos Url"
            name="google_photos_url"
            value={formData.google_photos_url}
            onChange={handleChange}
          />
          <CustomTextField
            label="Comentarios"
            name="comentarios"
            value={formData.comentarios}
            onChange={handleChange}
          />

          <FormControl fullWidth>
            <CustomInputLabel id="plaza-poli-select-label">
              Tipo de establecimiento
            </CustomInputLabel>
            <CustomSelect
              labelId="plaza-poli-select-label"
              id="plaza-poli-select"
              name={"plazaPolideportivo"}
              value={formData.plazaPolideportivo}
              label="PLAZA_O_POLIDEPORTIVO"
              onChange={handleChange}
              required
            >
              <MenuItem value={1}>Plaza</MenuItem>
              <MenuItem value={2}>Polideportivo</MenuItem>
            </CustomSelect>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            color: "white",
            "& .MuiFormControlLabel-label": {
              fontWeight: "bold",
            },
            width: "85%",
          }}
        >
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formData.abiertaSiempre}
                onChange={handleChange}
                name="abiertaSiempre"
              />
            }
            label="Abierta Siempre"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formData.aptaLluvia}
                onChange={handleChange}
                name="aptaLluvia"
              />
            }
            label="Apta para lluvia"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formData.canchaCompleta}
                onChange={handleChange}
                name="canchaCompleta"
              />
            }
            label="Cancha Completa"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formData.tieneRed}
                onChange={handleChange}
                name="tieneRed"
              />
            }
            label="Tiene red/cadenas"
          />
        </Box>
        {/* Selector de imágenes */}
        <div>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            id="fileInput" // ID para el input
            style={{ display: "none" }} // Ocultar el input original
          />
          <label
            htmlFor="fileInput"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: "black", // Color oscuro
              color: "white",
              borderRadius: "8px", // Bordes redondeados
              cursor: "pointer",
              fontWeight: "bold",
              textAlign: "center",
              border: "2px solid #EC5306", // Borde naranja
            }}
          >
            Seleccione las imagenes
          </label>
        </div>

        {/* Mostrar error si existe */}
        {error && <Box sx={{ color: "red" }}>{error}</Box>}

        {/* Mostrar archivos seleccionados */}

        {/*selectedFiles && (
          <Box sx={{ mt: 2 }}>
            <h3>Archivos seleccionados:</h3>
            <ul>
              {Array.from(selectedFiles).map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </Box>
        )*/}
      </Box>

      <div
        style={{
          width: "96%",
          marginLeft: "2%",
        }}
      >
        {/* Botón de enviar */}
        <ColorButton variant="contained" color="primary" onClick={handleSubmit}>
          Agregar Cancha
        </ColorButton>
      </div>
    </Box>
  );
}
